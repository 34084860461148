import 'core-js/stable';

import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';

import { getLocation } from '@metapackltd/dt-components';

import initialState from './initialState';
import LinkHandler from './LinkHandler';
import App from './app/App';

// IMPORTANT: Do *not* attempt to refactor the below `if/else` statement.
// Intentional `if/else` to allow Webpack to do dead code elimination,
// so development mode only code is eliminated from the production bundle.
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('./app/App.devProps')
    .getDevelopmentProps()
    .then(({ initialUrl, trackingData, languageData, brandConfig, urlParams }) => {
      renderApp({ initialUrl, trackingData, languageData, brandConfig, urlParams });
    });
} else {
  const { trackingData, languageData, brandConfig, nonce, reqQuery = {}, ssrError } = initialState();

  // IMPORTANT: Adding nonce to all scripts and styles that webpack loads
  // Reference: https://webpack.js.org/guides/csp/
  // eslint-disable-next-line no-undef, camelcase, no-underscore-dangle
  __webpack_nonce__ = nonce;

  const location = getLocation();
  const initialUrl = location.pathname ? `${location.pathname}${location.search}` : '/';

  renderApp({ initialUrl, trackingData, languageData, brandConfig, urlParams: reqQuery, ssrError });
}

function renderApp({ initialUrl, trackingData, languageData, brandConfig, urlParams, ssrError }) {
  const container = document.getElementById('root');
  const element = (
    <LinkHandler initialUrl={initialUrl}>
      {({ url, handleLink }) => (
        <App
          url={url}
          handleLink={handleLink}
          trackingData={trackingData}
          languageData={languageData}
          brandConfig={brandConfig}
          urlParams={urlParams}
          ssrError={ssrError}
        />
      )}
    </LinkHandler>
  );
  if (process.env.NODE_ENV !== 'production') {
    createRoot(container).render(element);
  } else {
    hydrateRoot(container, element);
  }
}
