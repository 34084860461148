import React from 'react';
import { App } from '@metapackltd/dt-components';

export default function AppWrapper(props) {
  const [showChild, setShowChild] = React.useState(false);

  React.useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  if (typeof window === 'undefined') {
    return <></>;
  }
  return <App {...props} />;
}
