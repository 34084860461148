/* eslint-disable no-underscore-dangle */

// initialState()
//
// NOTE: The extraction to a module here is merely to make index.js easier to test to
//       work-around it being otherwise very difficult to mock `window` at import-time.
//
export default function initialState() {
  return window.__INITIAL_STATE__ ? window.__INITIAL_STATE__ : {};
}
