import { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocation } from '@metapackltd/dt-components';

// LinkHandler - Minimal client-side link/URL handling.
//
// Avoids a full router library - at time of writing, the app only has 2 pages/URLs.
// IMPORTANT: Preserves the query params (`url.search`) from current URL.
//
// Usage:
//
//   <LinkHandler initialUrl="/">
//     {({ url, handleLink }) => (
//       ...
//       <a href="/privacy" onClick={handleLink} />
//
//       {url.startsWith('/privacy') && (
//         <h1>Privacy Page</h1>
//       )}
//       ...
//     )}
//   </LinkHandler>
//
export default class LinkHandler extends Component {
  state = { url: this.props.initialUrl };

  componentDidMount() {
    window.addEventListener('popstate', () => {
      this.setState({ url: getLocation().pathname });
    });
  }

  handleLink = e => {
    e.preventDefault();
    const newUrl = e.target.getAttribute('href') + (getLocation().search || '');

    setUrl(newUrl);
    this.setState({ url: newUrl });
  };

  render() {
    return this.props.children({ url: this.state.url, handleLink: this.handleLink });
  }
}

LinkHandler.propTypes = {
  initialUrl: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

function setUrl(url) {
  if (typeof window.history !== 'undefined' && window.history.pushState) {
    window.history.pushState(null, null, url);
  }
}
